@import '../colors';

$size: 200px;

.profile {
  margin-top: 24px;

  &-img-container {
    display: flex;
    justify-content: center;
  }

  &-img {
    border-radius: $size / 2;
    height: $size;
    width: $size;
  }

  &-name {
    color: white;
    font-size: 32px;
    padding-top: 12px;
    text-align: center;
  }
}
