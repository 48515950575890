@import '../colors';
@import '../layout';

@mixin edu-layout {
  display: flex;

  &--left {
    @include small-size {
      justify-content: flex-start;
      width: 80px;
    }

    display: flex;
    justify-content: center;
    width: 105px;

  }

  &--right {
    width: 100%;
  }
}

@mixin company-img {
  $img-size: 50px;

  img {
    border-radius: $img-size;
    height: $img-size;
    width: $img-size;
  }
}

@mixin dates {
  color: $grey-2;
}

@mixin item-title {
  font-size: 16px;
  font-weight: bold;
}

.resume {
  line-height: 1.7em;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 24px;
  }

  &-section {
    padding-bottom: 24px;

    &-title {
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 16px;

      .anticon {
        padding-right: 8px;
      }
    }

    &-content {
      padding: 0 8px;
    }

  }
}

.education-section {
  .school {
    @include edu-layout;

    &-title {
      @include item-title;
    }

    &-logo {
      @include company-img;
    }

    &-dates {
      @include dates;
    }
  }
}

.awards-section {
  .award {
    @include edu-layout;
  }
}

.work-experience {
  @include edu-layout;
  @include small-size {
    &--left {
      display: none;
    }
  }

  &-logo {
    @include company-img;
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-title {
    @include item-title;
  }

  &-dates {
    @include dates;
  }

  &-details {
    padding-top: 8px;
  }

  &-tech {
    li::before { // sass-lint:disable-line force-pseudo-nesting
      content: none !important;
    }
  }
}

.work-experience-item {
  ul {
    list-style: none;
    padding-inline-start: 24px;

    li::before { // sass-lint:disable-line force-pseudo-nesting
      color: $grey;
      content: '\2022';
      display: inline-block;
      margin-left: -1em;
      width: 1em;
    }
  }

  &-title {
    font-weight: bold;
    padding-bottom: 8px;
  }
}
