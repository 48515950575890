@import 'colors';
@import 'layout';
@import 'patterns';

.home {

  &-container {
    display: flex;
    flex-direction: row;

    .home-profile {
      @include pattern1;
      width: 60%;
    }

    @include medium-size {
      flex-direction: column;

      .resume {
        height: auto !important;
      }

      .home-profile {
        width: 100%;
      }

      .home-resume {
        padding-top: 16px;
      }
    }
  }

  .resume {
    height: 100vh;
    overflow: auto;
  }

  &-resume {
    background-color: $white;
    color: $black;
  }

  &-social-media-container {
    display: flex;
    justify-content: center;

    .social-media {
      padding-bottom: 24px;
      padding-top: 16px;
      width: 150px;
    }
  }
};
