@import '../colors';

.profile-item {
  display: flex;
  font-size: 17px;
  justify-content: center;

  &-content {
    padding-left: 8px;
  }

  .anticon {
    color: $yellow;
  }
}
