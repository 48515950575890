@import 'colors';

body {
  background-color: $black;
  color: $white;
  font-weight: 300;

  a {
    @include clickable-primary;
  }

  ::selection {
    background: $selection;
  }

  ::-moz-selection { // sass-lint:disable-line no-vendor-prefixes
    background: $selection;
  }
}

.app {
  &-content {
    display: flex;
    justify-content: center;
  }
}
