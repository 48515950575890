$black: #15161A;
$black-2: #242A2D;
$white: #fefefd;
$grey: #b1b4b6;
$grey-2: #9a9a9a;
$yellow: #FCD831;

$primary: #1173B9;
$secondary: #a5a7a9;
$selection: #1f262a;

@mixin clickable-primary {
  color: $primary;
  cursor: pointer;

  &:link,
  &:active {
    color: $primary;
  }

  &:hover {
    color: $secondary;
    text-decoration: none;
  }

  &:focus {
    outline: 1px dashed $primary;
  }
}
